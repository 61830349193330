var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}}},[_c('b-modal',{attrs:{"id":_vm.modalId,"centered":"","static":"","lazy":"","title":_vm.modalTitle,"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('b-button',{on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),(!_vm.disabled)?_c('submit-button',{attrs:{"handle-submit":handleSubmit,"submit":function (){ return _vm.save(ok); }}}):_vm._e()]}}],null,true)},[_c('fieldset',{attrs:{"disabled":_vm.disabled}},[_c('text-input',{attrs:{"id":"title","name":"Title","placeholder":"Add advertisement title","rules":"required","text":_vm.ad.title},on:{"update:text":function($event){return _vm.$set(_vm.ad, "title", $event)}}}),_c('b-form-row',[_c('b-col',{attrs:{"md":"6"}},[_c('date-time',{attrs:{"id":"start-date-time","datetime":_vm.ad.startTime,"name":"Start Datetime","rules":"required","placeholder":"Select start date","config":{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today',static:true},"is-edit-form-prop":_vm.editForm},on:{"update:datetime":function($event){return _vm.$set(_vm.ad, "startTime", $event)}}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('date-time',{attrs:{"id":"end-date-time","datetime":_vm.ad.endTime,"name":"End Datetime","placeholder":"Select end date","rules":"required","config":{
                enableTime:true,
                dateFormat: 'Y-m-d H:i',
                minDate:_vm.$moment(_vm.ad.startTime).add({minutes:1}).toDate(),
                'static':true,
              },"is-edit-form-prop":_vm.editForm},on:{"update:datetime":function($event){return _vm.$set(_vm.ad, "endTime", $event)}}})],1)],1),_c('text-input',{attrs:{"id":"order","name":"Order","rules":"required","type":"number","text":_vm.ad.order},on:{"update:text":function($event){return _vm.$set(_vm.ad, "order", $event)}}}),_c('b-form-group',{staticClass:"my-2",attrs:{"label":"Action Url Type","label-for":"action-url-type"}},[_c('b-form-radio-group',{attrs:{"id":"action-url-type","options":_vm.actionUrlOptions},model:{value:(_vm.actionUrlTypeProxy),callback:function ($$v) {_vm.actionUrlTypeProxy=$$v},expression:"actionUrlTypeProxy"}})],1),(_vm.actionUrlType === 'external')?_c('text-input',{attrs:{"id":"action-url","name":"Action URL","rules":"required|url","text":_vm.ad.callToActionUrl},on:{"update:text":function($event){return _vm.$set(_vm.ad, "callToActionUrl", $event)}}}):_vm._e(),(_vm.actionUrlType === 'internal')?_c('div',[_vm._t("ad-select")],2):_vm._e(),_c('div',[_c('custom-vue-select',{attrs:{"id":"ad-media-type","label":"Media Type","placeholder":"Select Media Type","value-reducer":function (option){ return option.value; },"options":[
              {text:'Image',value:'image'},
              {text:'Video',value:'video'} ],"selected":_vm.ad.media_type,"text-field":"text"},on:{"update:selected":function($event){return _vm.$set(_vm.ad, "media_type", $event)}}})],1),(_vm.ad.media_type==='image')?_c('div',[_c('div',[_c('upload-files',{attrs:{"id":"ad-image","disabled":_vm.disabled,"label":"Image","cropper":true,"stencil-props":{aspectRatio:1/1},"accept":"image/png, image/jpeg","rules":{ext:['png','jpeg','jpg'],required:!_vm.editForm},"files":_vm.ad.image},on:{"update:files":function($event){return _vm.$set(_vm.ad, "image", $event)}}}),_c('images-preview',{attrs:{"images":_vm.ad.image},on:{"update:images":function($event){return _vm.$set(_vm.ad, "image", $event)}}})],1)]):_c('div',[_c('div',[_c('text-input',{attrs:{"id":"ad-youtube-link","name":"Youtube Link","rules":"youtube-url|required","text":_vm.ad.youtube_link},on:{"update:text":function($event){return _vm.$set(_vm.ad, "youtube_link", $event)}}})],1),_c('div',[_c('upload-files',{attrs:{"id":"ad-youtube-cover","disabled":_vm.disabled,"label":"Youtube Cover","cropper":true,"stencil-props":{aspectRatio:1/1},"accept":"image/png, image/jpeg","rules":{ext:['png','jpeg','jpg'],required:!_vm.editForm},"files":_vm.ad.youtube_cover},on:{"update:files":function($event){return _vm.$set(_vm.ad, "youtube_cover", $event)}}}),_c('images-preview',{attrs:{"images":_vm.ad.youtube_cover},on:{"update:images":function($event){return _vm.$set(_vm.ad, "youtube_cover", $event)}}})],1)]),_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('b-form-radio-group',{attrs:{"id":"status","options":_vm.statusOptions},model:{value:(_vm.ad.status),callback:function ($$v) {_vm.$set(_vm.ad, "status", $$v)},expression:"ad.status"}})],1)],1)])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }