<template>
  <div>
    <!-- Add Button -->
    <div
      v-if="$can('store','global-AdsSliderController')"
      class="text-right"
    >
      <b-button
        v-b-modal.ad-add-modal
        variant="primary"
        class="mb-1"
      >
        <span class="text-nowrap">Add Advertisement</span>
      </b-button>
    </div>

    <!-- Filter -->
    <b-card header="Search">
      <b-row class="mb-1">
        <b-col md="6">
          <search
            placeholder="Title"
            :search-query.sync="adFilter.title"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col md="6">
          <date-range-filter :date-range.sync="adFilter.dateRange" />
        </b-col>

        <b-col md="6">
          <status-filter :status.sync="adFilter.status" />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <!-- Table -->
    <ads-table
      ref="parentTable"
      v-bind="{
        getAds,
        pagination
      }"
      :item.sync="ad"
    />

    <!-- Pagination -->
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <div>
      <!-- Add AD -->
      <ad-form
        modal-id="ad-add-modal"
        modal-title="Add Advertisement"
        :ad="ad"
        :submit="addAd"
        @hidden="resetAd"
      >
        <template #ad-select>
          <slot
            name="ad-select-parent"
            :ad="ad"
          />
        </template>
      </ad-form>

      <!-- Edit AD -->
      <ad-form
        v-if="ad.title"
        modal-id="ad-edit-modal"
        modal-title="Edit Advertisement"
        :ad="ad"
        :submit="updateAd"
        :edit-form="true"
        @hidden="resetAd"
      >
        <template #ad-select>
          <slot
            name="ad-select-parent"
            :ad="ad"
          />
        </template>
      </ad-form>

      <!-- View AD -->
      <ad-form
        v-if="ad.title"
        modal-id="ad-view-modal"
        modal-title="View Advertisement"
        :ad="ad"
        :disabled="true"
        @hidden="resetAd"
      >
        <template #ad-select>
          <slot
            name="ad-select-parent"
            :ad="ad"
          />
        </template>
      </ad-form>
    </div>
  </div>
</template>

<script>
import AdsTable from '@/common/components/AppContent/ads/AdsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

import handleAlerts from '@/common/compositions/common/handleAlerts'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import paginationData from '@/common/compositions/common/paginationData'
import handleFormData from '@/common/compositions/common/handleFormData'
import AdForm from '@/common/components/AppContent/ads/AdForm.vue'
import StatusFilter from '../../common/FormInputs/StatusFilter.vue'

export default {
  name: 'AdsList',
  components: {
    AdsTable,
    Pagination,
    Search,
    SearchButton,
    DateRangeFilter,
    AdForm,
    StatusFilter,
  },
  props: {
    classification: { type: String, default: '' },
  },
  data() {
    return {
      ad: {
        typeId: '', status: true, image: [], order: 0, youtube_cover: [], media_type: 'image',
      },
      adFilter: { title: null, dateRange: [] },
    }
  },
  computed: {
    formattedAd() {
      return {
        ...this.ad,
        startTime: this.$moment(this.ad.startTime).format('YYYY-MM-DD HH:mm'),
        endTime: this.$moment(this.ad.endTime).format('YYYY-MM-DD HH:mm'),
        typeId: this.ad.typeModel?.id,
        ...(this.ad.image.length ? { image: this.ad.image[0] } : {}),
        ...(this.ad.youtube_cover.length ? { youtube_cover: this.ad.youtube_cover[0] } : {}),
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { setFormData, formData } = handleFormData()
    const { pagination } = paginationData()
    return {
      successfulOperationAlert,
      pagination,
      setFormData,
      formData,
    }
  },
  methods: {
    getAds() {
      return this.$activities.get('/internalops/ads/slider', {
        params: {
          page: this.pagination.currentPage,
          ...this.adFilter,
          startTime: this.adFilter.dateRange[0],
          endTime: this.adFilter.dateRange[1],
          classification: this.classification,
        },
      }).then(res => {
        const items = res.data.data

        this.pagination.totalRows = res.data.pagination.total
        return items || []
      }).catch(() => [])
    },
    addAd() {
      this.setFormData({
        ...this.formattedAd,
        classification: this.classification,
      })

      return this.$activities.post('/internalops/ads/slider', this.formData).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Advertisement is added successfully')
        this.refreshTable()
      })
    },
    updateAd() {
      this.setFormData({ ...this.formattedAd, classification: this.classification })

      return this.$activities.post(`/internalops/ads/slider/${this.ad.id}?_method=PUT`, this.formData).then(() => {
        this.$bvModal.hide('edit-modal')
        this.successfulOperationAlert('Advertisement is updated successfully')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
    resetAd() {
      this.ad = {
        typeId: '', status: true, image: [], order: 0,
      }
    },
  },
}
</script>
