<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <b-modal
        :id="modalId"
        centered
        static
        lazy
        :title="modalTitle"
        no-close-on-backdrop
        @hidden="$emit('hidden')"
      >
        <fieldset :disabled="disabled">
          <!-- Title -->
          <text-input
            id="title"
            name="Title"
            placeholder="Add advertisement title"
            rules="required"
            :text.sync="ad.title"
          />
          <b-form-row>
            <!-- Start Time -->
            <b-col md="6">
              <date-time
                id="start-date-time"
                :datetime.sync="ad.startTime"
                name="Start Datetime"
                rules="required"
                placeholder="Select start date"
                :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today',static:true}"
                :is-edit-form-prop="editForm"
              />
            </b-col>
            <!-- End Time -->
            <b-col md="6">
              <date-time
                id="end-date-time"
                :datetime.sync="ad.endTime"
                name="End Datetime"
                placeholder="Select end date"
                rules="required"
                :config="{
                  enableTime:true,
                  dateFormat: 'Y-m-d H:i',
                  minDate:$moment(ad.startTime).add({minutes:1}).toDate(),
                  'static':true,
                }"
                :is-edit-form-prop="editForm"
              />
            </b-col>
          </b-form-row>
          <!-- Order -->
          <text-input
            id="order"
            name="Order"
            rules="required"
            type="number"
            :text.sync="ad.order"
          />
          <b-form-group
            label="Action Url Type"
            label-for="action-url-type"
            class="my-2"
          >
            <b-form-radio-group
              id="action-url-type"
              v-model="actionUrlTypeProxy"
              :options="actionUrlOptions"
            />
          </b-form-group>
          <!-- Action URL -->
          <text-input
            v-if="actionUrlType === 'external'"
            id="action-url"
            name="Action URL"
            rules="required|url"
            :text.sync="ad.callToActionUrl"
          />
          <!-- FundraisingProjectSelect -->
          <div
            v-if="actionUrlType === 'internal'"
          >
            <slot
              name="ad-select"
            />
          </div>
          <div>
            <custom-vue-select
              id="ad-media-type"
              label="Media Type"
              placeholder="Select Media Type"
              :value-reducer="option=>option.value"
              :options="[
                {text:'Image',value:'image'},
                {text:'Video',value:'video'},
              ]"
              :selected.sync="ad.media_type"
              text-field="text"
            />
          </div>
          <!-- Image -->
          <div v-if="ad.media_type==='image'">
            <div>
              <upload-files
                id="ad-image"
                :disabled="disabled"
                label="Image"
                :cropper="true"
                :stencil-props="{aspectRatio:1/1}"
                accept="image/png, image/jpeg"
                :rules="{ext:['png','jpeg','jpg'],required:!editForm}"
                :files.sync="ad.image"
              />

              <images-preview
                :images.sync="ad.image"
              />
            </div>
          </div>

          <!-- Youtube Link -->
          <div v-else>
            <div>
              <text-input
                id="ad-youtube-link"
                name="Youtube Link"
                rules="youtube-url|required"
                :text.sync="ad.youtube_link"
              />
            </div>

            <!-- Youtube Cover -->
            <div>
              <upload-files
                id="ad-youtube-cover"
                :disabled="disabled"
                label="Youtube Cover"
                :cropper="true"
                :stencil-props="{aspectRatio:1/1}"
                accept="image/png, image/jpeg"
                :rules="{ext:['png','jpeg','jpg'],required:!editForm}"
                :files.sync="ad.youtube_cover"
              />

              <images-preview
                :images.sync="ad.youtube_cover"
              />
            </div>
          </div>
          <!-- Ad Status -->
          <b-form-group
            label="Status"
            label-for="status"
          >
            <b-form-radio-group
              id="status"
              v-model="ad.status"
              :options="statusOptions"
            />
          </b-form-group>
        </fieldset>

        <template #modal-footer="{cancel,ok}">
          <b-button
            @click="cancel()"
          >
            Cancel
          </b-button>

          <submit-button
            v-if="!disabled"
            :handle-submit="handleSubmit"
            :submit="()=>save(ok)"
          />
        </template>

        <!-- Form Actions -->
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'
import DateTime from '../../common/FormInputs/DateTime.vue'
import UploadFiles from '../../common/FormInputs/UploadFiles.vue'
import ImagesPreview from '../../common/FormInputs/ImagesPreview.vue'
import CustomVueSelect from '../../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'AdForm',
  components: {
    SubmitButton,
    TextInput,
    DateTime,
    UploadFiles,
    ImagesPreview,
    CustomVueSelect,
  },
  props: {
    ad: { type: Object, default: () => {} },
    submit: { type: Function, default: null },
    modalId: { type: String, default: '' },
    modalTitle: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      actionUrlType: 'no_action',
      actionUrlOptions: [
        { text: 'No Action', value: 'no_action' },
        { text: 'External', value: 'external' },
        { text: 'Internal', value: 'internal' },
      ],
      statusOptions: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
    }
  },
  computed: {
    actionUrlTypeProxy: {
      get() {
        return this.actionUrlType
      },
      set(value) {
        this.actionUrlType = value
        this.$delete(this.ad, 'type')
        this.$delete(this.ad, 'typeId')
        this.$delete(this.ad, 'callToActionUrl')
      },
    },
  },
  created() {
    this.setActionUrlType()
  },
  methods: {
    save(ok) {
      return this.submit().then(() => {
        ok()
      })
    },
    setActionUrlType() {
      if (this.ad.callToActionUrl) this.actionUrlType = 'external'
      else if (this.ad.type) this.actionUrlType = 'internal'
      else this.actionUrlType = 'no_action'
    },
  },
}
</script>

<style>

</style>
