<template>
  <b-card>
    <b-table
      ref="table"
      :items="getAds"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No Items found"
      show-empty
    >
      <template #cell(image)="{item}">
        <div>
          <b-avatar
            :src="item.image"
            rounded
            size="140px"
          />
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="$can('show','global-AdsSliderController')"
            v-b-modal.ad-view-modal
            @click="setItem(item)"
          >
            <feather-icon icon="FileTextIcon" />
            <span> View </span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="toggleStatus(item)"
          >
            <feather-icon icon="ToggleRightIcon" />
            <span> {{ item.status ? 'Deactivate' : 'Activate' }} </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update','global-AdsSliderController')"
            v-b-modal.ad-edit-modal
            @click="setItem(item)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('delete','global-AdsSliderController')"
            @click="deleteAd(item)"
          >
            <div
              class="text-danger"
            >
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Delete</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ItemsTable',
  props: {
    getAds: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'image' },
        { key: 'title' },
        { key: 'startTime', formatter: v => this.$moment(v).format('YYYY-MM-DD HH:mm') },
        { key: 'endTime', formatter: v => this.$moment(v).format('YYYY-MM-DD HH:mm') },
        { key: 'status', formatter: v => (v ? 'Active' : 'Inactive') },
        { key: 'order' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/ads/slider/`,
    }
  },
  setup() {
    const { confirmOperation, successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert,
      confirmOperation,
    }
  },
  methods: {
    toggleStatus(item) {
      const message = item.status ? 'Ad is deactivated successfully' : 'Ad is activated successfully'

      this.$activities.post(`/internalops/ads/slider/${item.id}/toggle/status`).then(() => {
        item.status = !item.status
        this.successfulOperationAlert(message)
      })
    },
    setItem(item) {
      this.$emit('update:item', {
        ...item, typeModel: { ...item.typeModel, name: item.typeModel?.event_name }, image: [item.image], youtube_cover: [item.youtube_cover], media_type: item.image ? 'image' : 'video',
      })
    },
    deleteAd(item) {
      this.confirmOperation().then(() => {
        this.$activities.delete(`/internalops/ads/slider/${item.id}`).then(() => {
          this.$refs.table.refresh()
        })
      })
    },
  },
}
</script>
